<template>
  <b-sidebar
    id="sidebar-purchase-add-new-product"
    ref="refProductSidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    :width="$store.getters['app/currentBreakPoint'] === 'xs' ? '100%' : '420px'"
    @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('Add New Product') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- First Name -->
          <validation-provider
            #default="validationContext"
            :name="t('Name')"
            rules="required"
          >
            <b-form-group
              :label="t('Name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="product.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="t('Group')"
            rules="required"
          >
            <b-form-group
              :label="t('Group')"
              label-for="group"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="product.group"
                :state="getValidationState(validationContext)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groups"
                label="group"
                input-id="group"
                taggable
                push-tags
                :clearable="false"
                @option:created="val => addOption(val, 'group')"
              >
                <template #list-header>
                  <li
                    v-if="optionsLoading"
                    class="text-center text-primary my-2"
                  >
                    <b-spinner
                      class="align-middle mr-1"
                    />
                    <strong>Загрузка...</strong>
                  </li>
                  <!-- <li
                    v-if="!optionsLoading && groups.length === 0"
                    class="text-center text-primary my-2"
                  >
                    <strong>Type for add new</strong>
                  </li> -->
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Last Name -->
          <validation-provider
            #default="validationContext"
            :name="t('Unit')"
            rules="required"
          >
            <b-form-group
              :label="t('Unit')"
              label-for="unit"
            >
              <v-select
                v-model="product.unit"
                :state="getValidationState(validationContext)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="units"
                label="unit"
                input-id="unit"
                taggable
                push-tags
                :clearable="false"
                @option:created="val => addOption(val, 'unit')"
              >
                <template #list-header>
                  <li
                    v-if="optionsLoading"
                    class="text-center text-primary my-2"
                  >
                    <b-spinner
                      class="align-middle mr-1"
                    />
                    <strong>Загрузка...</strong>
                  </li>
                  <!-- <li
                    v-if="!optionsLoading && groups.length === 0"
                    class="text-center text-primary my-2"
                  >
                    <strong>Type for add new</strong>
                  </li> -->
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- manufacturer -->
          <validation-provider
            #default="validationContext"
            :name="t('Manufacturer')"
            rules=""
          >
            <b-form-group
              :label="t('Manufacturer')"
              label-for="manufacturer"
            >
              <v-select
                v-model="product.manufacturer"
                :state="getValidationState(validationContext)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="manufacturers"
                label="manufacturer"
                input-id="manufacturer"
                taggable
                push-tags
                :clearable="false"
                @option:created="val => addOption(val, 'manifacture')"
              >
                <template #list-header>
                  <li
                    v-if="optionsLoading"
                    class="text-center text-primary my-2"
                  >
                    <b-spinner
                      class="align-middle mr-1"
                    />
                    <strong>Загрузка...</strong>
                  </li>
                  <!-- <li
                    v-if="!optionsLoading && groups.length === 0"
                    class="text-center text-primary my-2"
                  >
                    <strong>Type for add new</strong>
                  </li> -->
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- init_price -->
          <validation-provider
            #default="validationContext"
            :name="t('Purchase price')"
            rules="required"
          >
            <b-form-group
              :label="t('Purchase price')"
              label-for="init_price"
            >
              <b-form-input
                id="init_price"
                v-model="product.init_price"
                :state="getValidationState(validationContext)"
                trim
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- last_price -->
          <validation-provider
            #default="validationContext"
            :name="t('Sale price')"
            rules="required"
          >
            <b-form-group
              :label="t('Sale price')"
              label-for="last_price"
            >
              <b-form-input
                id="last_price"
                v-model="product.last_price"
                :state="getValidationState(validationContext)"
                trim
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- discount_price -->
          <validation-provider
            #default="validationContext"
            :name="t('Discount price')"
            rules="required"
          >
            <b-form-group
              :label="t('Discount price')"
              label-for="discount_price"
            >
              <b-form-input
                id="discount_price"
                v-model="product.discount_price"
                :state="getValidationState(validationContext)"
                trim
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Contact -->
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="product.description"
              type="text"
              placeholder=""
              trim
            />
          </b-form-group>

          <!-- code -->
          <validation-provider
            #default="validationContext"
            :name="t('Code')"
            rules="required"
          >
            <b-form-group
              :label="t('Code')"
              label-for="code"
            >
              <b-form-input
                id="code"
                v-model="product.code"
                :state="getValidationState(validationContext) && codeDuplicated"
                trim
                @input="val => checkForCode(val)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] || 'Code Duplicated' }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="submitOnProcess"
            >
              <b-spinner
                v-if="submitOnProcess"
                small
                type="grow"
              />
              {{ t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :disabled="submitOnProcess"
              @click="hide"
            >
              {{ t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'
// Validation
import {
  required, alphaNum, email, length, min,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, vSelect, BButton, ValidationProvider, ValidationObserver, BFormInvalidFeedback, BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      length,
      min,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const toast = useToast()
    const refProductSidebar = ref(null)

    const blankProductData = {
      group: '',
      name: '',
      manufacturer: '',
      unit: '',
      init_price: 0,
      last_price: 0,
      discount_price: 0,
      description: '',
      code: '',
    }

    const product = ref({
      group: '',
      name: '',
      manufacturer: '',
      unit: '',
      init_price: 0,
      last_price: 0,
      discount_price: 0,
      description: '',
      code: '',
    })

    const groups = ref([])
    const manufacturers = ref([])
    const units = ref([])
    const optionsLoading = ref(true)
    store.dispatch('app-sales/fetchOptions')
      .then(response => {
        groups.value = response.data.records.map(item => item.group).filter(item => item != null)
        manufacturers.value = response.data.records.map(item => item.manifacture).filter(item => item != null)
        units.value = response.data.records.map(item => item.unit).filter(item => item != null)
      })
      .finally(() => {
        optionsLoading.value = false
      })

    const addOption = (value, column) => {
      console.log(value, column)
      const payload = { }
      payload[column] = value
      store.dispatch('app-sales/addOption', payload)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно добавили новый выбор',
            },
          })
        })
    }

    const resetProductData = () => {
      product.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProductData)

    const submitOnProcess = ref(false)

    const onSubmit = () => {
      submitOnProcess.value = true
      store.dispatch('app-sales/addProduct', product.value)
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить и продать.',
            },
          })
          emit('refetch-data', response.data)
          // emit('update:is-add-new-product-sidebar-active', false)
        })
        .finally(() => {
          submitOnProcess.value = false
          refProductSidebar.value.hide()
        })
    }

    const codeDuplicated = ref(false)
    const checkForCode = code => {
      store.dispatch('app-sales/checkForCode', code)
        .then(response => {
          if (response.data.records.length !== 0) {
            codeDuplicated.value = false
          } else {
            codeDuplicated.value = true
          }
        })
    }

    return {
      product,
      t,
      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      refProductSidebar,
      submitOnProcess,
      groups,
      manufacturers,
      units,
      optionsLoading,
      addOption,
      checkForCode,
      codeDuplicated,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
