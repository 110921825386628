<template>
  <b-sidebar
    id="sidebar-purchase-add-new-car"
    ref="refCarSidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    :width="$store.getters['app/currentBreakPoint'] === 'xs' ? '100%' : '420px'"
    @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('Add New Car') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Car Number -->
          <validation-provider
            #default="validationContext"
            :name="t('Car Number')"
            rules="required|alpha-num"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              :label="t('Car Number')"
              label-for="car-number"
            >
              <b-form-input
                id="car-number"
                v-model="car.number"
                autofocus
                trim
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Comments -->
          <validation-provider
            #default="validationContext"
            :name="t('Comments')"
            rules=""
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              :label="t('Comments')"
              label-for="comments"
            >
              <b-form-textarea
                id="comments"
                v-model="car.comments"
                autofocus
                trim
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="submitOnProcess"
            >
              <b-spinner
                v-if="submitOnProcess"
                small
                type="grow"
              />
              {{ t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :disabled="submitOnProcess"
              @click="hide"
            >
              {{ t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'
// Validation
import {
  required, alphaNum, email, length, min,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, ValidationProvider, ValidationObserver, BFormInvalidFeedback, BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      length,
      min,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const toast = useToast()
    const refCarSidebar = ref(null)

    const blankCarData = {
      number: '',
      comments: '',
    }

    const car = ref({
      number: '',
      comments: '',
    })

    const resetCarData = () => {
      car.value = JSON.parse(JSON.stringify(blankCarData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCarData)

    const submitOnProcess = ref(false)

    const onSubmit = () => {
      submitOnProcess.value = true
      store.dispatch('app-sales/addCar', {
        number: car.value.number.replace(' ', ''),
        comments: car.value.comments,
      })
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить и продать.',
            },
          })
          emit('refetch-data', response.data)
          // emit('update:is-add-new-supplier-sidebar-active', false)
        })
        .finally(() => {
          submitOnProcess.value = false
          refCarSidebar.value.hide()
        })
    }

    return {
      car,
      t,
      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      refCarSidebar,
      submitOnProcess,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
