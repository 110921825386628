<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="salesData === undefined"
    >
      <h4 class="alert-heading">
        {{ t('Error fetching purchase data') }}
      </h4>
      <div class="alert-body">
        {{ t('No purchase found with this purchase id. Check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          {{ t('Purchase List') }}
        </b-link>
        {{ t('for other purchases.') }}
      </div>
    </b-alert>
    <validation-observer
      v-show="!salesDataLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmitSave)"
        @reset.prevent="resetForm"
      >
        <b-row class="invoice-add">
          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >

              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column purchase-spacing mt-0">

                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary invoice-logo">
                        swm
                      </h3>
                    </div>
                    <!-- <p class="card-text mb-25">
                      company name, address, itn
                    </p>
                    <p class="card-text mb-25">
                      bank account, etc
                    </p>
                    <p class="card-text mb-0">
                      +998946393966
                    </p> -->
                    <!-- <b-card-text class="mb-25">
                      Office 149, 450 South Brand Brooklyn
                    </b-card-text>
                    <b-card-text class="mb-25">
                      San Diego County, CA 91905, USA
                    </b-card-text>
                    <b-card-text class="mb-0">
                      +1 (123) 456 7891, +44 (876) 543 2198
                    </b-card-text> -->
                  </div>

                  <!-- Header: Right Content -->
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="">
                        {{ t('Продать товар') }}
                      </h4>
                      <!-- <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="salesData.id"
                          disabled
                        />
                      </b-input-group> -->
                    </div>
                    <!-- <div class="d-flex align-items-right justify-content-end mb-1">
                      <h4 class="invoice-title">
                        {{ t('Приход товара') }}
                      </h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="salesData.id"
                          disabled
                        />
                      </b-input-group>
                    </div> -->
                    <div class="d-flex align-items-center justify-content-end">
                      <span class="title align-middle mb-1">
                        {{ t('Date') }}:
                      </span>
                      <validation-provider
                        #default="validationContext"
                        :name="t('Date')"
                        rules="required"
                      >
                        <b-form-group
                          :state="getValidationState(validationContext)"
                          class="invoice-edit-input-group"
                        >
                          <flat-pickr
                            v-model="salesData.date"
                            class="form-control invoice-edit-input"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <!-- <div class="d-flex align-items-center">
                      <span class="title">
                        Due Date:
                      </span>
                      <flat-pickr
                        v-model="salesData.dueDate"
                        class="form-control purchase-edit-input"
                      />
                    </div> -->
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row class="invoice-spacing">

                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Warehouse') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Warehouse')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select warehouse -->
                        <v-select
                          ref="refWarehouseOpt"
                          v-model="salesData.warehouse"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="warehouses"
                          label="name"
                          input-id="purchase-data-client"
                          :clearable="false"
                          @input="val => getProductsbyWm(val.id)"
                        >
                          <template #list-header>
                            <li
                              v-b-toggle.sidebar-purchase-add-new-warehouse
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add New Warehouse') }}</span>
                            </li>
                            <li
                              v-if="warehouses.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                                small
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                          <template #selected-option="{ name, defaults }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50"> {{ name }}</span>
                              <b-badge
                                v-if="defaults === '1'"
                                pill
                                :variant="`light-primary`"
                              >
                                <small>{{ t('Default') }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template #option="{ name, type, defaults }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <div>
                                <span class="align-middle"> {{ name }}</span>
                                <b-badge
                                  v-if="defaults === '1'"
                                  pill
                                  :variant="`light-secondary`"
                                >
                                  <small>{{ t('Default') }}</small>
                                </b-badge>
                              </div>
                              <b-badge
                                v-if="type !== null"
                                pill
                                :variant="`light-secondary`"
                                class="mr-55"
                              >
                                <small>{{ t(type) }}</small>
                              </b-badge>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <!-- Selected warehouse -->
                    <div
                      v-if="salesData.warehouse"
                      class="mt-1"
                    >
                      <!-- <li class="d-flex justify-content-between align-items-end">
                        <b-badge
                          pill
                          :variant="`light-${resolveBalanceColor(Math.floor(Math.random()*15)*1000000-3000000)}`"
                        >
                          <small>{{ t('Ostatka') }}: </small>
                          {{ `${Math.floor(Math.random()*15)*1000000-3000000} ${ t('t') }` }}
                        </b-badge>
                      </li> -->
                      <!-- <h6 class="mb-1">
                        {{ t('Ostatka') }}:
                      </h6>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">
                              Product1:
                            </td>
                            <td><span class="font-weight-bold">110.55</span></td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Product2:
                            </td>
                            <td><span class="font-weight-bold">10.55</span></td>
                          </tr>
                        </tbody>
                      </table> -->
                    </div>
                  </b-col>

                  <!-- Col: Invoice To -->
                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Customer') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Customer')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select supplier -->
                        <v-select
                          ref="refSupplierOpt"
                          v-model="salesData.supplier"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="suppliers"
                          label="firstname"
                          input-id="purchase-data-client"
                          :clearable="false"
                          @input="val => salesData.responsible = users.find(u => u.id === val.resp_user_id)"
                        >
                          <template #list-header>
                            <li
                              v-b-toggle.sidebar-purchase-add-new-supplier
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new customer') }}</span>
                            </li>
                            <li
                              v-if="suppliers.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                          <template #selected-option="{ firstname, surname, defaults }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50"> {{ `${firstname} ${surname}` }}</span>
                              <b-badge
                                v-if="defaults === '1'"
                                pill
                                :variant="`light-primary`"
                              >
                                <small>{{ t('Default') }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template #option="{ firstname, surname, defaults, balance }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <div class="align-items-center">
                                <span class="align-middle"> {{ `${firstname} ${surname}` }}</span>
                                <b-badge
                                  v-if="defaults === '1'"
                                  pill
                                  :variant="`light-primary`"
                                >
                                  <small>{{ t('Default') }}</small>
                                </b-badge>
                              </div>
                              <b-badge
                                pill
                                :variant="`light-${resolveBalanceColor(balance)}`"
                                class="mr-55"
                              >
                                <small>Balance: </small>
                                <!-- {{ label }} -->
                                {{ roundUp(balance) }}
                              </b-badge>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- Selected supplier -->
                    <div
                      v-if="salesData.supplier"
                      class="mt-1"
                    >
                      <li class="d-flex justify-content-between align-items-end">
                        <b-badge
                          pill
                          :variant="`light-${resolveBalanceColor(salesData.supplier.balance)}`"
                        >
                          <small>{{ t('Balance') }}: </small>
                          <!-- {{ label }} -->
                          {{ roundUp(salesData.supplier.balance) }}
                        </b-badge>
                      </li>
                      <!-- <h6 class="mb-25">
                        {{ salesData.supplier.firstname }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ salesData.supplier.surname }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ salesData.supplier.address }}, {{ salesData.supplier.city }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ salesData.supplier.phone }}
                      </b-card-text>
                      <b-card-text class="mb-0">
                        {{ salesData.supplier.email }}
                      </b-card-text> -->
                    </div>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Car') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Car')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select warehouse -->
                        <v-select
                          ref="refCarOpt"
                          v-model="salesData.car"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="cars"
                          label="name"
                          input-id="purchase-data-client"
                          taggable
                          push-tags
                          :clearable="false"
                        >
                          <template #list-header>
                            <li
                              v-b-toggle.sidebar-purchase-add-new-car
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new car') }}</span>
                            </li>
                            <li
                              v-if="cars.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <!-- Selected warehouse -->
                    <!-- <div
                      v-if="salesData.car"
                      class="mt-1"
                    >
                      <h6 class="mb-25">
                        {{ salesData.car.name }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ salesData.car.number }}
                      </b-card-text>
                    </div> -->
                  </b-col>

                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Responsible') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Responsible')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          ref="refRespOpt"
                          v-model="salesData.responsible"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="users"
                          label="name"
                          input-id="purchase-data-responsible"
                          :clearable="false"
                        >
                          <template #list-header>
                            <li
                              v-if="users.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                          <template #selected-option="{ name, active }">
                            <b-avatar
                              size="26"
                              :text="avatarText(name)"
                              :variant="`light-${resolveUserAvatarVariant(active)}`"
                            />
                            <span class="ml-50 d-inline-block align-middle">
                              {{ name }}
                            </span>
                          </template>
                          <template #option="{ name, active }">
                            <b-avatar
                              size="26"
                              :text="avatarText(name)"
                              :variant="`light-${resolveUserAvatarVariant(active)}`"
                            />
                            <span class="ml-50 d-inline-block align-middle">
                              {{ name }}
                            </span>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <!-- Selected warehouse -->
                    <!-- <div
                      v-if="salesData.car"
                      class="mt-1"
                    >
                      <h6 class="mb-25">
                        {{ salesData.car.name }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ salesData.car.number }}
                      </b-card-text>
                    </div> -->
                  </b-col>

                  <!-- Col: Payment Details -->
                  <!-- <b-col
                    xl="6"
                    cols="12"
                    class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                  >
                    <div>
                      <h6 class="mb-2">
                        Payment Details:
                      </h6>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">
                              Total Due:
                            </td>
                            <td><span class="font-weight-bold">$12,110.55</span></td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Bank name:
                            </td>
                            <td>American Bank</td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Country:
                            </td>
                            <td>United States</td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              IBAN:
                            </td>
                            <td>ETD95476213874685</td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              SWIFT code:
                            </td>
                            <td>BR91905</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col> -->
                </b-row>
              </b-card-body>

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in salesData.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >

                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">

                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            {{ t('Product') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            {{ t('Cost') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            {{ t('Price') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            {{ t('Qty') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            {{ t('Weight') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="1"
                          >
                            {{ t('Total price') }}
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 pt-2 pl-1 pr-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="t('Product')"
                              rules="required"
                            >
                              <b-form-group
                                :state="getValidationState(validationContext)"
                                class="mb-0"
                              >
                                <label class="d-inline d-lg-none">{{ t('Product') }}</label>
                                <v-select
                                  ref="refProductOpt"
                                  v-model="item.product"
                                  :state="getValidationState(validationContext)"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="products"
                                  label="itemTitle"
                                  :clearable="false"
                                  class="mb-2 item-selector-title"
                                  placeholder="Select Item"
                                  @input="val => updateItemForm(index, val)"
                                >
                                  <template #list-header>
                                    <!-- <li
                                      v-b-toggle.sidebar-purchase-add-new-product
                                      class="add-new-client-header d-flex align-items-center my-50"
                                    >
                                      <feather-icon
                                        icon="PlusIcon"
                                        size="16"
                                      />
                                      <span class="align-middle ml-25">{{ t('Add new product') }}</span>
                                    </li> -->
                                    <li
                                      v-if="products.length === 0 && salesData.warehouse"
                                      class="text-center text-primary my-2"
                                    >
                                      <b-spinner
                                        class="align-middle mr-1"
                                      />
                                      <strong>Загрузка...</strong>
                                    </li>
                                    <li
                                      v-else-if="!salesData.warehouse"
                                      class="text-center text-primary my-2"
                                    >
                                      <strong>Please select warehouse first...</strong>
                                    </li>
                                  </template>
                                  <!-- <template #selected-option="{ itemTitle }"> -->
                                  <!-- <feather-icon
                                      :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                      size="16"
                                      class="align-middle mr-50"
                                    /> -->
                                  <!-- <li class="d-flex justify-content-between align-items-center">
                                      <span class="align-middle mr-50"> {{ itemTitle }}</span> -->
                                  <!-- <b-badge
                                        v-if="product.id === 2"
                                        pill
                                        :variant="`light-primary`"
                                      >
                                        <small>{{ t('Default') }}</small>
                                      </b-badge> -->
                                  <!-- </li>
                                  </template> -->
                                  <template #option="{ itemTitle, product }">
                                    <!-- <feather-icon
                                      :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                      size="16"
                                      class="align-middle mr-50"
                                    /> -->
                                    <li class="d-flex justify-content-between align-items-center">
                                      <div class="align-items-center">
                                        <span class="align-middle"> {{ itemTitle }}</span>
                                        <!-- <b-badge
                                          v-if="product.id === 2"
                                          pill
                                          :variant="`light-primary`"
                                        >
                                          <small>{{ t('Default') }}</small>
                                        </b-badge> -->
                                      </div>
                                      <b-badge
                                        pill
                                        :variant="`light-${resolveBalanceColor(product.quantity)}`"
                                        class="pr-60"
                                      >
                                        <!-- {{ label }} -->
                                        {{ product.quantity }}
                                      </b-badge>
                                    </li>
                                  </template>
                                </v-select>
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="t('Cost')"
                              rules="required"
                            >
                              <b-form-group
                                :state="getValidationState(validationContext)"
                                class="mb-0"
                              >
                                <label class="d-inline d-lg-none">{{ t('Cost') }}</label>
                                <b-form-input
                                  v-model="item.cost"
                                  :state="getValidationState(validationContext)"
                                  type="number"
                                  class="mb-2"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>

                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="t('Price')"
                              rules="required"
                            >
                              <b-form-group
                                :state="getValidationState(validationContext)"
                                class="mb-0"
                              >
                                <label class="d-inline d-lg-none">{{ t('Price') }}</label>
                                <b-form-input
                                  v-model="item.price"
                                  :state="getValidationState(validationContext)"
                                  type="number"
                                  class="mb-2"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>

                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="t('Qty')"
                              rules="required"
                            >
                              <b-form-group
                                :state="getValidationState(validationContext)"
                                class="mb-0"
                              >
                                <label class="d-inline d-lg-none">{{ t('Qty') }}</label>
                                <b-form-input
                                  v-model="item.quantity"
                                  :state="getValidationState(validationContext)"
                                  type="number"
                                  class="mb-2"
                                  @input="val => updateSubItemQty(index, val)"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="t('Weight')"
                              :rules="`required|max_value:${item && item.product && item.product.product ? item.product.product.quantity : 0}`"
                            >
                              <b-form-group
                                :state="getValidationState(validationContext)"
                                class="mb-0"
                              >
                                <label class="d-inline d-lg-none">{{ t('Weight') }}</label>
                                <b-form-input
                                  :id="`form-item-settings-icon-${index}`"
                                  v-model="item.weight"
                                  :state="getValidationState(validationContext)"
                                  type="number"
                                  class="mb-2"
                                  readonly
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="1"
                          >
                            <label class="d-inline d-lg-none">{{ t('Total price') }}</label>
                            <p class="mb-1 text-nowrap">
                              {{ roundUpCustom(item.price && item.weight ? item.price * item.weight : 0, 2) }}
                            </p>
                          </b-col>
                          <!-- <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline d-lg-none">{{ t('Description') }}</label>
                            <b-form-textarea
                              v-model="item.description"
                              class="mb-2 mb-lg-0"
                            />
                          </b-col> -->
                          <!-- <b-col>
                            <p class="mb-0">
                              Discount: 0% 0% 0%
                            </p>
                          </b-col> -->
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                          <!-- Setting Item Form -->
                          <b-popover
                            v-if="parseFloat(item.quantity)>0"
                            :ref="`form-item-settings-popover-${index}`"
                            :target="`form-item-settings-icon-${index}`"
                            triggers="click"
                            placement="lefttop"
                          >
                            <b-form @submit.prevent>
                              <b-row>
                                <!-- Field: Discount -->
                                <b-col
                                  cols="12"
                                  class="mb-1"
                                >
                                  <div class="d-flex justify-content-between align-items-center">
                                    <label for="totalWeightActivator">{{ t('Total weight') }}</label>
                                    <b-form-checkbox
                                      id="totalWeightActivator"
                                      v-model="totalWeightActivator"
                                      switch
                                    />
                                  </div>
                                </b-col>
                                <b-col
                                  v-if="totalWeightActivator"
                                  cols="12"
                                >
                                  <b-form-input
                                    :id="`setting-subitem-${index}-weight`"
                                    v-model="item.weight"
                                    type="number"
                                    placeholder="Weight"
                                    class="mb-1"
                                  />
                                </b-col>
                                <b-col
                                  v-if="!totalWeightActivator"
                                  cols="12"
                                >
                                  <b-form-input
                                    v-for="(subItem, i) in item.subItems"
                                    :id="`setting-subitem-${index}-weight`"
                                    :key="i"
                                    v-model="subItem.weight"
                                    type="number"
                                    :placeholder="`Weight ${i+1}`"
                                    class="mb-1"
                                  />
                                </b-col>

                                <b-col
                                  cols="12"
                                  class="d-flex justify-content-between mt-1"
                                >
                                  <b-button
                                    variant="outline-primary"
                                    @click="() => {onSubmitWeight(index); $refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                                  >
                                    Apply
                                  </b-button>
                                  <b-button
                                    variant="outline-secondary"
                                    @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                                  >
                                    Cancel
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form>
                          </b-popover>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  {{ t('Add Item') }}
                </b-button>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>

                  <!-- Col: Sales Persion -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  >
                    <!-- <label
                      for="purchase-data-sales-person"
                      class="text-nowrap mr-50"
                    >Sales Person:</label>
                    <b-form-input
                      id="purchase-data-sales-person"
                      v-model="salesData.salesPerson"
                      placeholder="Edward Crowley"
                    /> -->
                  </b-col>

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <!-- <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Subtotal:
                        </p>
                        <p class="invoice-total-amount">
                          $1800
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Discount:
                        </p>
                        <p class="invoice-total-amount">
                          $28
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Tax:
                        </p>
                        <p class="invoice-total-amount">
                          21%
                        </p>
                      </div> -->
                      <!-- <hr class="my-50"> -->
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ t('Subtotal') }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ roundUpCustom(parseFloat(totalPrice), 2) }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ t('Extra costs') }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ roundUpCustom(parseFloat(salesData.extra_costs.amount ? salesData.extra_costs.amount : 0), 2) }}
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ t('Total') }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ roundUpCustom(parseFloat(totalPrice) + parseFloat(salesData.extra_costs.amount ? salesData.extra_costs.amount : 0), 2) }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">{{ t('Note') }}: </span>
                <b-form-textarea v-model="salesData.note" />
              </b-card-body>
            </b-card>

          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >

            <!-- Action Buttons -->
            <b-card>

              <!-- Button: Send Invoice -->
              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                :to="{ name: 'apps-purchase-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  class="mx-auto"
                  size="18"
                />
                {{ t('Back to list') }}
              </b-button> -->

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
                @click.prevent="handleSubmit(onSubmitSave)"
              >
                <b-spinner
                  v-if="submitOnProcess.save"
                  small
                  type="grow"
                />
                {{ t('Sell') }}
              </b-button>

              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
                @click.prevent="handleSubmit(onSubmitSaveSell)"
              >
                <b-spinner
                  v-if="submitOnProcess.saveandsell"
                  small
                />
                {{ t('Save and sell') }}
              </b-button> -->

              <!-- Button: Draft -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mb-75"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
                @click.prevent="handleSubmit(onSubmitToDraft)"
              >
                <b-spinner
                  v-if="submitOnProcess.todraft"
                  small
                  type="grow"
                />
                {{ t('To draft') }}
              </b-button>

              <!-- Button: Cancel -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
                :to="{ name: 'apps-sales-list'}"
              >
                {{ t('Cancel') }}
              </b-button>
            </b-card>

            <!-- Payment Method -->
            <div
              class="mt-2"
            >
              <div class="d-flex justify-content-between align-items-center my-1">
                <label for="onCredit">{{ t('On Credit') }}</label>
                <b-form-checkbox
                  id="onCredit"
                  v-model="salesData.onCredit"
                  switch
                />
              </div>

              <!-- Extra costs -->
              <div class="d-flex justify-content-between align-items-center my-1">
                <label for="extraCosts">{{ t('Extra costs') }}</label>
                <b-form-checkbox
                  id="extraCosts"
                  v-model="salesData.toggler"
                  switch
                />
              </div>

              <div class="align-items-center my-1">
                <validation-provider
                  v-show="salesData.toggler"
                  #default="validationContext"
                  :name="t('Kassa')"
                  :rules="salesData.toggler ? 'required' : ''"
                >
                  <b-form-group
                    :label="t('Kassa')"
                    label-for="kassa"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="salesData.extra_costs.kassa"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="kassa"
                      :options="accounts"
                      :state="getValidationState(validationContext)"
                      input-id="kassa"
                      class="payment-selector"
                      :clearable="false"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="align-items-center my-1">
                <validation-provider
                  v-show="salesData.toggler"
                  #default="validationContext"
                  :name="t('Amount')"
                  :rules="salesData.toggler ? 'required' : ''"
                >
                  <b-form-group>
                    <label>{{ t('Amount') }}</label>
                    <b-form-input
                      v-model="salesData.extra_costs.amount"
                      :state="getValidationState(validationContext)"
                      type="number"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>

              <!-- Payment Stub -->
              <!-- <div class="d-flex justify-content-between align-items-center">
                <label for="paymentStub">Payment Stub</label>
                <b-form-checkbox
                  id="paymentStub"
                  switch
                />
              </div> -->
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <div
      v-show="salesDataLoading"
      class="text-center text-primary my-2"
    >
      <b-spinner
        class="align-middle mr-1"
      />
      <strong>{{ t('Загрузка') }}...</strong>
    </div>
    <sales-sidebar-add-new-suplier
      @refetch-data="refetchSuppliers"
    />
    <sales-sidebar-add-new-warehouse
      @refetch-data="refetchWarehouses"
    />
    <sales-sidebar-add-new-car
      @refetch-data="refetchCars"
    />
    <sales-sidebar-add-new-product
      @refetch-data="refetchProducts"
    />
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import router from '@/router'
import {
  ref, onUnmounted, watchEffect, computed,
} from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Validation
import {
  required, alphaNum, email, length, min, max_value,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BCardBody, BButton, BForm, BFormInput, BFormInvalidFeedback, BFormGroup, BFormTextarea, BFormCheckbox, BPopover, VBToggle, BSpinner, BBadge, BAlert, BLink, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import salesStoreModule from '../salesStoreModule'
import SalesSidebarAddNewSuplier from '../SalesSidebarAddNewSuplier.vue'
import SalesSidebarAddNewWarehouse from '../SalesSidebarAddNewWarehouse.vue'
import SalesSidebarAddNewCar from '../SalesSidebarAddNewCar.vue'
import SalesSidebarAddNewProduct from '../SalesSidebarAddNewProduct.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    // BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // BInputGroup,
    // BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BSpinner,
    BPopover,
    BBadge,
    flatPickr,
    vSelect,
    Logo,
    SalesSidebarAddNewSuplier,
    SalesSidebarAddNewWarehouse,
    SalesSidebarAddNewCar,
    SalesSidebarAddNewProduct,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // BFormFile,
    BAlert,
    BLink,
    // BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      alphaNum,
      email,
      length,
      min,
      max_value,
    }
  },
  watch: {
    salesData(value) {
      console.log('pd updated', value)
      // console.log(this.$refs.refFile)
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.salesData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    changeHeightInItemForm() {
      this.$refs.form.style.overflow = 'hidden'

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.salesData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.form.scrollHeight)
      // })
    },
  },
  setup() {
    const toast = useToast()
    const SALES_APP_STORE_MODULE_NAME = 'app-sales'
    const { t } = useI18nUtils()
    const totalWeightActivator = ref(false)
    const refRespOpt = ref(null)

    // Register module
    if (!store.hasModule(SALES_APP_STORE_MODULE_NAME)) store.registerModule(SALES_APP_STORE_MODULE_NAME, salesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALES_APP_STORE_MODULE_NAME)) store.unregisterModule(SALES_APP_STORE_MODULE_NAME)
    })

    // const suppliers = (ctx, callback) => {
    //   store
    //     .dispatch('app-sales/fetchSupliers')
    //     .then(response => {
    //       const { records } = response.data

    //       callback(records)
    //     })
    //     .catch(() => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Error fetching suppliers' list",
    //           icon: 'AlertTriangleIcon',
    //           variant: 'danger',
    //         },
    //       })
    //     })
    // }

    const cars = ref([])
    store.dispatch('app-sales/fetchCars')
      .then(response => {
        cars.value = response.data.records.map(item => {
          const newItem = {
            id: item.id,
            name: item.number,
          }
          return newItem
        })
      })

    const accounts = ref([])

    store.dispatch('app-sales/getSubOptions', 'AccountSuppliers')
      .then(response => {
        accounts.value = response.data.records
      })

    const users = ref([])
    store.dispatch('app-sales/fetchUsers')
      .then(response => {
        users.value = response.data.records.map(item => {
          const newItem = {
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            active: item.active,
          }
          return newItem
        })
      })

    const itemFormBlankItem = {
      product: null,
      cost: null,
      price: null,
      quantity: null,
      weight: null,
      subItems: [
        {
          weight: null,
        },
      ],
      description: '',
      subId: 0,
    }

    const blanksalesData = {
      date: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 10).replace('T', ' '),
      supplier: null,
      warehouse: null,
      car: null,
      responsible: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      // salesPerson: null,
      note: '',
      toggler: false,
      extra_costs: {
        kassa_id: null,
        amount: null,
        id: null,
        kassa: null,
        type: null,
      },
      onCredit: true,
      // paymentMethod: null,
    }

    const salesData = ref({
      date: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 10).replace('T', ' '),
      supplier: null,
      warehouse: null,
      car: null,
      responsible: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      // salesPerson: null,
      note: '',
      toggler: false,
      extra_costs: {
        kassa_id: null,
        amount: null,
        id: null,
        kassa: null,
        type: null,
      },
      onCredit: true,
      // paymentMethod: null,
    })

    // const salesData = ref({})

    const warehouses = ref([])
    store.dispatch('app-sales/fetchWarehouses')
      .then(response => {
        warehouses.value = response.data.records
        return response.data.records
      })
      // .then(response => {
      //   response.forEach(item => {
      //     if (item.defaults === '1') {
      //       salesData.value.warehouse = item
      //     }
      //   })
      // })

    const suppliers = ref([])
    store.dispatch('app-sales/fetchSupliers')
      .then(response => {
        suppliers.value = response.data.records
        return response.data.records
      })
      // .then(response => {
      //   response.forEach(item => {
      //     if (item.defaults === '1') {
      //       salesData.value.supplier = item
      //     }
      //   })
      // })

    const resetsalesData = () => {
      salesData.value = JSON.parse(JSON.stringify(blanksalesData))
    }
    const refWarehouseOpt = ref(null)
    const refetchWarehouses = val => {
      warehouses.value.unshift(val)
      refWarehouseOpt.value.$emit('input', val)
      // store.dispatch('app-sales/fetchWarehouses')
      //   .then(response => { warehouses.value = response.data.records })
    }

    const products = ref([])
    const getProductsbyWm = async id => {
      salesData.value.items = [JSON.parse(JSON.stringify(itemFormBlankItem))]

      await store.dispatch('app-sales/fetchWarehouses')
        .then(response => {
          warehouses.value = response.data.records
          return response.data.records
        })

      const checkTransit = warehouses.value.find(x => x.name === 'Транзит').id

      if (checkTransit === id) {
        await store.dispatch('app-sales/getProductsbyWm', id)
          .then(response => {
            products.value = response.data
              .map(item => {
                const newItem = {
                  product: {
                    id: item.product_id,
                    name: item.name,
                    quantity: parseFloat(item.quantity),
                  },
                  itemTitle: `${item.code} ${item.name}`,
                  cost: parseFloat(item.init_price),
                  price: parseFloat(item.last_price),
                  quantity: null,
                  weight: item.quantity,
                  subItems: [
                    {
                      weight: 0,
                    },
                  ],
                  description: null,
                  subId: 0
                }
                return newItem
              })
          })
      } else {
        await store.dispatch('app-sales/getSubitemsByWm', id)
          .then(response => {
            products.value = response.data
              .map(item => {
                const newItem = {
                  product: {
                    id: item.product_id,
                    name: item.name,
                    quantity: parseFloat(item.weight),
                  },
                  itemTitle: `${item.code} ${item.name}`,
                  cost: parseFloat(item.init_price),
                  quantity: 1,
                  weight: item.weight,
                  subItems: [
                    {
                      weight: null,
                    },
                  ],
                  description: null,
                  subId: item.id
                }
                return newItem
              })
            console.log('products.value', products.value)
          })
      }
    }

    const salesDataLoading = ref(false)

    if (router.currentRoute.params.tablename === 'purchase' && router.currentRoute.params.id) {
      salesDataLoading.value = true
      store.dispatch('app-sales/fetchPurchase', { id: router.currentRoute.params.id })
        .then(response => {
          console.log(response.data)
          if (response.data === null) {
            salesData.value = undefined
          } else if (response.data.actiontype === 'saveandsell' && response.data.sale_id === null) {
            const item = response.data
            getProductsbyWm(item.Warehouse.id)
            salesData.value.id = item.id
            salesData.value.date = item.order_date
            salesData.value.warehouse = item.Warehouse

            salesData.value.car = item.orderCars.map(car => ({
              id: car.id,
              name: car.number,
            })).shift()
            salesData.value.items = item.Products.map(product => ({
              product: {
                product: {
                  id: product.id,
                  name: product.name,
                  quantity: parseFloat(product.OrdersProducts.weight),
                },
                itemTitle: `${product.code} ${product.name}`,
                cost: parseFloat(product.init_price),
                quantity: parseFloat(product.OrdersProducts.weight),
                weight: null,
                subItems: [
                  {
                    weight: null,
                  },
                ],
                description: product.description,
              },
              itemTitle: `${product.code} ${product.name}`,
              cost: parseFloat(product.OrdersProducts.cost),
              quantity: parseFloat(product.OrdersProducts.quantity),
              weight: product.OrdersProducts.weight,
              subItems: product.WmSubitems.map(subitems => ({
                weight: parseFloat(subitems.weight),
                id: subitems.id
              })),
              description: product.OrdersProducts.description,
            }))
            salesData.value.note = ''
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'This purchase is not closed, please income first',
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          salesData.value = undefined
        })
        .finally(() => {
          salesDataLoading.value = false
        })
    }
    if (router.currentRoute.params.tablename === 'wmlist') {
      salesDataLoading.value = true
      store.dispatch('app-sales/fetchWmList', { userId: store.state.app.userData.id })
        .then(response => {
          console.log(response.data)
          const item = response.data
          if (item === null || item.products === undefined || item.products.length === 0) {
            salesData.value = undefined
          } else {
            getProductsbyWm(item.Warehouse.id)
            // salesData.value.id = item.id
            salesData.value.date = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 10).replace('T', ' ')
            salesData.value.warehouse = item.Warehouse
            salesData.value.car = null
            salesData.value.items = item.products.map(product => ({
              product: {
                product: {
                  id: product.product_id,
                  name: product.product_name,
                  quantity: parseFloat(product.weight),
                },
                itemTitle: `${product.product_code} ${product.product_name}`,
                cost: parseFloat(product.init_price),
                price: parseFloat(product.last_price),
                quantity: parseFloat(product.weight),
                weight: null,
                subItems: [
                  {
                    weight: null,
                  },
                ],
                description: '',
              },
              itemTitle: `${product.product_code} ${product.product_name}`,
              cost: parseFloat(product.init_price),
              price: parseFloat(product.last_price),
              quantity: parseFloat(product.quantity),
              weight: product.weight,
              subItems: [
                {
                  weight: null,
                },
              ],
              description: '',
              id: product.id
            }))
            salesData.value.note = ''
          }
        })
        .catch(error => {
          console.log(error)
          salesData.value = undefined
        })
        .finally(() => {
          salesDataLoading.value = false
        })
    }
    // [
    //   {
    //     itemTitle: 'Уголок 30x30x3',
    //     cost: 24,
    //     quantity: 1,
    //     weight: 0,
    //     subItems: [
    //       {
    //         weight: 0,
    //       },
    //     ],
    //     description: 'lorom, ipsum, dolor',
    //   },
    //   {
    //     itemTitle: 'Уголок 40x40x3',
    //     cost: 26,
    //     quantity: 1,
    //     weight: 0,
    //     subItems: [
    //       {
    //         weight: 0,
    //       },
    //     ],
    //     description: 'lorom',
    //   },
    //   {
    //     itemTitle: 'Уголок 50x50x3',
    //     cost: 28,
    //     quantity: 1,
    //     weight: 0,
    //     subItems: [
    //       {
    //         weight: 0,
    //       },
    //     ],
    //     description: 'ipsum',
    //   },
    //   {
    //     itemTitle: 'Уголок 60x60x3',
    //     cost: 32,
    //     quantity: 1,
    //     weight: 0,
    //     subItems: [
    //       {
    //         weight: 0,
    //       },
    //       // {
    //       //   weight: 0,
    //       // },
    //       // {
    //       //   weight: 0,
    //       // },
    //     ],
    //     description: 'dolor',
    //   },
    // ]

    const updateItemForm = (index, val) => {
      console.log('updateItemForm', val)
      const {
        cost,
        quantity,
        description,
        weight,
        subItems,
        subId,
        price,
      } = val
      salesData.value.items[index].cost = cost
      salesData.value.items[index].quantity = quantity
      salesData.value.items[index].weight = weight
      salesData.value.items[index].subItems = subItems
      salesData.value.items[index].description = description
      salesData.value.items[index].subId = subId
      salesData.value.items[index].price = price

      console.log('salesData.value', salesData.value)
    }

    const updateSubItemQty = (index, val) => {
      // console.log(index, val, salesData.value.items[index].subItems)
      const arr = []
      for (let i = 0; i < val; i = 1 + i) {
        arr.push({ weight: null })
      }
      salesData.value.items[index].subItems = arr
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetsalesData)

    watchEffect(() => console.log('salesData.value', salesData.value))
    watchEffect(() => console.log('products.value', products.value))

    const refSupplierOpt = ref(null)
    const refCarOpt = ref(null)
    const refProductOpt = ref(null)

    const refetchSuppliers = val => {
      suppliers.value.unshift(val)
      refSupplierOpt.value.$emit('input', val)
      // salesData.value.supplier = val
      // refSupplierOpt.value.$emit('option:selected', val)
      // setTimeout(() => {
      // refSupplierOpt.value.$emit('close')
      // }, 1000)
      // this.$nextTick(() => {
      //   refSupplierOpt.value.close()
      // })

      // store.dispatch('app-sales/fetchSupliers')
      //   .then(response => { suppliers.value = response.data.records })
    }

    const refetchCars = val => {
      const newVal = val
      newVal.name = `${val.name} ${val.number}`
      cars.value.unshift(newVal)
      refCarOpt.value.$emit('input', newVal)
      // store.dispatch('app-sales/fetchCars')
      //   .then(response => {
      //     cars.value = response.data.records.map(item => {
      //       const newItem = item
      //       newItem.name = item.number
      //       return newItem
      //     })
      //   })
    }

    const refetchProducts = val => {
      const newVal = {
        product: val,
        itemTitle: `${val.code} ${val.name}`,
        cost: val.init_price,
        quantity: val.quantity,
        weight: null,
        subItems: [
          {
            weight: null,
          },
        ],
        description: val.description,
      }
      products.value.unshift(newVal)
      // refProductOpt.value.$emit('input', newVal)

      // store.dispatch('app-sales/fetchProducts')
      //   .then(response => {
      //     products.value = response.data.records
      //       .map(item => {
      //         const newItem = {
      //           product: item,
      //           itemTitle: item.name,
      //           cost: item.init_price,
      //           quantity: item.quantity,
      //           weight: 0,
      //           subItems: [
      //             {
      //               weight: 0,
      //             },
      //           ],
      //           description: item.description,
      //         }
      //         return newItem
      //       })
      //   })
    }

    const submitOnProcess = ref({
      save: false,
      saveandsell: false,
      todraft: false,
    })
    const onSubmitSave = () => {
      submitOnProcess.value.save = true
      // console.log('salesData.value.file', salesData.value.file)
      const payload = JSON.parse(JSON.stringify(salesData.value))
      payload.actiontype = 'save'
      payload.status = 'Closed'
      payload.order_id = router.currentRoute.params.id
      // payload.supplier = payload.supplier.contact
      // payload.warehouse = payload.warehouse.contact
      // payload.car = payload.car.contact
      // payload.items = payload.items.map( item => {
      //   item.product = item.product.contact
      // })

      payload.supplier = payload.supplier.id
      payload.warehouse = payload.warehouse.id
      payload.car = payload.car.id ? payload.car.id : payload.car
      payload.responsible = payload.responsible.id
      payload.items = payload.items.map(item => {
        const newItem = item
        newItem.product = item.product.product.id
        return newItem
      })
      // payload.file = salesData.value.file
      console.log('payload', payload)
      store.dispatch('app-sales/createSale', payload)
        .then(response => {
          console.log(response)
          // const { records } = response.data
          // if (records) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          if (salesData.value.onCredit) router.push({ name: 'apps-sales-preview', params: { id: response.data.sale_id } })
          else router.push({ name: 'apps-transaction-add', params: { id: response.data.sale_id } })
          // }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new purchase',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          submitOnProcess.value.save = false
        })
    }

    // // const onSubmitSaveSell = () => {
    //   submitOnProcess.value.saveandsell = true
    //   const payload = JSON.parse(JSON.stringify(salesData.value))
    //   payload.actiontype = 'saveandsell'
    //   payload.status = 'New'
    //   // payload.supplier = payload.supplier.contact
    //   // payload.warehouse = payload.warehouse.contact
    //   // payload.car = payload.car.contact
    //   // payload.items = payload.items.map( item => {
    //   //   item.product = item.product.contact
    //   // })

    //   payload.supplier = payload.supplier.id
    //   payload.warehouse = payload.warehouse.id
    //   payload.car = payload.car.id ? payload.car.id : payload.car
    //   payload.items = payload.items.map(item => {
    //     const newItem = item
    //     newItem.product = item.product.product.id
    //     return newItem
    //   })
    //   // console.log(payload)
    //   // payload.file = salesData.value.file
    //   store.dispatch('app-sales/createSale', payload)
    //     .then(response => {
    //       console.log(response)
    //       // const { records } = response.data
    //       // if (records) {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Успешно',
    //           icon: 'CheckCircleIcon',
    //           variant: 'success',
    //           text: 'Вы успешно нажали на кнопку сохранить.',
    //         },
    //       })
    //       resetForm()
    //       router.push({ name: 'apps-sales-preview', params: { id: response.data.sale_id } })
    //       // }
    //     })
    //     .catch(error => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Error adding new purchase',
    //           icon: 'AlertTriangleIcon',
    //           variant: 'danger',
    //           text: error.message,
    //         },
    //       })
    //     })
    //     .finally(() => {
    //       submitOnProcess.value.saveandsell = false
    //     })
    // }

    const onSubmitToDraft = () => {
      submitOnProcess.value.todraft = true
      const payload = JSON.parse(JSON.stringify(salesData.value))
      payload.actiontype = 'todraft'
      payload.status = 'Draft'
      payload.order_id = router.currentRoute.params.id
      // payload.supplier = payload.supplier.contact
      // payload.warehouse = payload.warehouse.contact
      // payload.car = payload.car.contact
      // payload.items = payload.items.map( item => {
      //   item.product = item.product.contact
      // })

      payload.supplier = payload.supplier.id
      payload.warehouse = payload.warehouse.id
      payload.car = payload.car.id ? payload.car.id : payload.car
      payload.responsible = payload.responsible.id
      payload.items = payload.items.map(item => {
        const newItem = item
        newItem.product = item.product.product.id
        return newItem
      })
      // payload.file = salesData.value.file
      console.log(payload)
      store.dispatch('app-sales/createSale', payload)
        .then(response => {
          console.log(response)
          // const { records } = response.data
          // if (records) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          router.push({ name: 'apps-sales-preview', params: { id: response.data.sale_id } })
          // }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new purchase',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          submitOnProcess.value.todraft = false
        })
    }
    function addZeroes(givnum, deci) {
      let num = Number(givnum)
      if (Number.isNaN(num)) {
        return 0
      }
      if (String(num).split('.').length < deci || String(num).split('.')[1].length <= deci) {
        num = num.toFixed(deci)
      }
      return num
    }

    const onSubmitWeight = index => {
      if (totalWeightActivator.value === false) {
        const temp = salesData.value.items[index].subItems.reduce((total, item) => total + parseFloat(item.weight), 0)
        salesData.value.items[index].weight = addZeroes(temp, 3)
      }
    }

    const paymentMethods = [
      'Bank Account',
      'Uzcard',
      'Cash',
    ]

    const resolveBalanceColor = value => {
      if (value > 0) return 'success'
      if (value === 0) return 'primary'
      if (value < 0) return 'danger'
      return 'secondary'
    }

    const totalPrice = computed(() => salesData.value.items.reduce((total, item) => total + parseFloat(item.price && item.weight ? item.price * item.weight : 0), 0))

    // const fileSubmit = val => {
    //   const formData = new FormData()
    //   formData.append('file', val)
    //   store.dispatch('app-sales/addFile', formData)
    //     .catch(error => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Error adding new purchase',
    //           icon: 'AlertTriangleIcon',
    //           variant: 'danger',
    //           text: error.message,
    //         },
    //       })
    //     })
    // }

    const resolveUserAvatarVariant = status => {
      // if (status === 'New') return 'primary'
      // // if (status === 'Closed') return 'danger'
      // // if (status === 'Downloaded') return 'secondary'
      // if (status === 'Draft') return 'warning'
      // if (status === 'Sent') return 'info'
      if (status === 1) return 'success'
      return 'danger'
    }
    const roundUp = num => {
      const x = num ? (Math.round(num * 10) / 10) : 0
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    }
    function roundUpCustom(givnum, decimals = 2) {
      if (givnum !== null) {
        const num = Number.isNaN(givnum) ? 0 : givnum
        const tem = 10 ** decimals
        const preval = (
          Math.round(
            num * tem + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / (100 ** decimals)))
          ) / tem
        ).toFixed(decimals)
        const parts = preval.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        return parts.join('.')
      }
      return 0
    }

    return {
      roundUpCustom,
      salesData,
      suppliers,
      warehouses,
      cars,
      users,
      products,
      updateItemForm,
      updateSubItemQty,
      itemFormBlankItem,
      paymentMethods,
      totalWeightActivator,
      t,
      onSubmitWeight,
      onSubmitSave,
      // onSubmitSaveSell,
      onSubmitToDraft,
      submitOnProcess,
      refFormObserver,
      getValidationState,
      resetForm,
      refetchSuppliers,
      refetchWarehouses,
      refetchCars,
      refetchProducts,
      refSupplierOpt,
      refWarehouseOpt,
      refCarOpt,
      refProductOpt,
      resolveBalanceColor,
      totalPrice,
      refRespOpt,
      // fileSubmit,
      salesDataLoading,
      resolveUserAvatarVariant,
      avatarText,
      getProductsbyWm,
      roundUp,
      accounts,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.purchase-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
