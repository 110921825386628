<template>
  <b-sidebar
    id="sidebar-purchase-add-new-warehouse"
    ref="refWarehouseSidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    :width="$store.getters['app/currentBreakPoint'] === 'xs' ? '100%' : '420px'"
    @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('Add New Warehouse') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Warehouse Name -->
          <validation-provider
            #default="validationContext"
            :name="t('Warehouse Name')"
            rules="required"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              :label="t('Warehouse Name')"
              label-for="warehouse-name"
            >
              <b-form-input
                id="warehouse-name"
                v-model="warehouse.name"
                autofocus
                trim
                placeholder=""
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Address -->
          <validation-provider
            #default="validationContext"
            :name="t('Address')"
            rules="required"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              :label="t('Address')"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="warehouse.address"
                placeholder="221B Baker Street"
                trim
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- City -->
          <validation-provider
            #default="validationContext"
            :name="t('City')"
            rules="required"
          ><b-form-group
            :state="getValidationState(validationContext)"
            label="City"
            label-for="city"
          >
            <v-select
              v-model="warehouse.city"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['ANDIJAN','BUKHARA','DJIZZAK','FERGANA','KARAKALPAKISTAN','KASHKADARYA','KHOREZM','NAMANGAN','NAVOI','SAMARKAND','SIRDARYA','SURKHANDARYA','TASHKENT']"
              :clearable="false"
              input-id="city"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="t('Comments')"
            rules=""
          >
            <b-form-group
              :label="t('Comments')"
              label-for="comments"
              :state="getValidationState(validationContext)"
            >
              <b-form-textarea
                id="comments"
                v-model="warehouse.comments"
                type="text"
                :state="getValidationState(validationContext)"
                placeholder=""
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="t('Responsible')"
            rules=""
          >
            <b-form-group
              :label="t('Responsible')"
              label-for="responsible"
              :state="getValidationState(validationContext)"
            >
              <v-select
                ref="refRespOpt"
                v-model="warehouse.resp_user_id"
                :state="getValidationState(validationContext)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="users"
                label="name"
                :reduce="val => val.id"
                input-id="responsible"
                :clearable="false"
              >
                <template #list-header>
                  <li
                    v-if="users.length === 0"
                    class="text-center text-primary my-2"
                  >
                    <b-spinner
                      class="align-middle mr-1"
                    />
                    <strong>Загрузка...</strong>
                  </li>
                </template>
                <template #selected-option="{ name, active }">
                  <b-avatar
                    size="26"
                    :text="avatarText(name)"
                    :variant="`light-${resolveUserAvatarVariant(active)}`"
                  />
                  <span class="ml-50 d-inline-block align-middle">
                    {{ name }}
                  </span>
                </template>
                <template #option="{ name, active }">
                  <b-avatar
                    size="26"
                    :text="avatarText(name)"
                    :variant="`light-${resolveUserAvatarVariant(active)}`"
                  />
                  <span class="ml-50 d-inline-block align-middle">
                    {{ name }}
                  </span>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex justify-content-between align-items-center">
            <label for="type">{{ t('Transit') }}</label>
            <b-form-checkbox
              id="type"
              v-model="warehouse.type"
              switch
            />
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="submitOnProcess"
            >
              <b-spinner
                v-if="submitOnProcess"
                small
                type="grow"
              />
              {{ t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :disabled="submitOnProcess"
              @click="hide"
            >
              {{ t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'
// Validation
import {
  required, alphaNum, email, length, min,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormInvalidFeedback, BSpinner, BAvatar, BFormCheckbox,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, vSelect, ValidationProvider, ValidationObserver, BFormInvalidFeedback, BSpinner, BAvatar, BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      length,
      min,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const toast = useToast()
    const refWarehouseSidebar = ref(null)

    const blankWarehouseData = {
      name: '',
      address: '',
      city: '',
      comments: '',
      resp_user_id: '',
      type: false,
    }

    const warehouse = ref({
      name: '',
      address: '',
      city: '',
      comments: '',
      resp_user_id: '',
      type: false,
    })

    const resetWarehouseData = () => {
      warehouse.value = JSON.parse(JSON.stringify(blankWarehouseData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetWarehouseData)

    const users = ref([])
    store.dispatch('app-sales/fetchUsers')
      .then(response => {
        users.value = response.data.records.map(item => {
          const newItem = {
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            active: item.active,
          }
          return newItem
        })
      })

    const submitOnProcess = ref(false)

    const onSubmit = () => {
      submitOnProcess.value = true
      warehouse.value.type = warehouse.value.type ? 'Transit' : null
      store.dispatch('app-sales/addWarehouse', warehouse.value)
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить и продать.',
            },
          })
          emit('refetch-data', response.data)
          // emit('update:is-add-new-user-sidebar-active', false)
        })
        .finally(() => {
          submitOnProcess.value = false
          refWarehouseSidebar.value.hide()
        })
    }

    const resolveUserAvatarVariant = status => {
      // if (status === 'New') return 'primary'
      // // if (status === 'Closed') return 'danger'
      // // if (status === 'Downloaded') return 'secondary'
      // if (status === 'Draft') return 'warning'
      // if (status === 'Sent') return 'info'
      if (status === 1) return 'success'
      return 'secondary'
    }

    return {
      warehouse,
      t,
      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      refWarehouseSidebar,
      submitOnProcess,
      users,
      resolveUserAvatarVariant,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
